import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    // priceRangeDefined: 'all',
    // priceRange: [0, 100],
    categories: [],
    brands: [],
    //ratings: null,
    page: 1,
    perPage: 9,
  })

  const filterOptions = {
    categories: [
      'Terraform',
      'Serverless',
      'CloudFormation',
      'AWS CDK'
    ],
    brands: ['Data', 'Acillary', 'Modernization', 'Custom Architecture'],
  }

  // Sorting
  const sortBy = ref({ text: 'Lowest', value: 'price-asc' })
  const sortByOptions = [
    { text: 'Lowest', value: 'price-asc' },
    { text: 'Highest', value: 'price-desc' },
  ]

  const clearFilters = () => {
    filters.value.brands = []
    filters.value.categories = []
  }

  return {
    // Filter
    filters,
    filterOptions,
    
    // reset
    clearFilters,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'list-view'
  const itemViewOptions = [
    //{ icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const fetchProducts = (...args) => store.dispatch('app-ecommerce/FETCH_BUSINESS_PRODUCTS', ...args)


  return {
    products,
    fetchProducts,
  }
}
